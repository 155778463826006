/* eslint-disable */
import React from 'react';
import HafhLogo from '@assets/hafh-logo.svg';
import Link from '@components/Link';
import routes from '@utils/routes';
import styles from './index.css';

const FooterLogo = () => (
  <div className="footer-logo">
    <Link href={routes.root}>
      <div className="footer-logo-image">
        <HafhLogo className="hafh-logo" />
      </div>
    </Link>
    <style jsx={true}>{styles}</style>
  </div>
);

export default FooterLogo;
