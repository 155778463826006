import React from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { useSupportLanguages } from '@hooks/useSupportLanguages';
import * as neighborActions from '@redux/modules/neighbor';
import auth from '@services/firebase';
import IconArrow from '@svg-icons/IconArrow';
import { ALL_LANGUAGES } from '@utils/constants';
import { createCookie } from '@utils/cookie';
import { useActions } from '@utils/hooks';
import { isEmpty } from '@utils/utils';
import { useRouter } from 'next/router';
import styles from './css';

const FooterLang = () => {
  const { authUser, neighbor } = useAppSelector((state) => ({
    authUser: state.authUser,
    neighbor: state.neighbor,
  }));
  const { updateNeighborLanguage } = useActions({ ...neighborActions });
  const router = useRouter();
  const { asPath, locale, pathname } = router;
  const supportLanguages = useSupportLanguages({
    includeCurrentLanguage: true,
  });

  const changeLang = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value;

    if (!isEmpty(neighbor)) {
      const langId =
        ALL_LANGUAGES.find((language) => language.key === newLocale)?.value ??
        2;
      const params = { language_id: langId };
      await updateNeighborLanguage(params, authUser, newLocale);
    }

    router.push(pathname, asPath, { locale: newLocale });
    createCookie('NEXT_LOCALE', newLocale);
    auth.languageCode = newLocale;
  };

  return (
    <div className="footer-lang">
      <div className="footer-lang-wrap">
        <div className="footer-lang-select">
          <select id="language-select" onChange={changeLang} value={locale}>
            {supportLanguages.map((language) => (
              <option key={language.key} value={language.key}>
                {language.label}
              </option>
            ))}
          </select>
          <div className="footer-lang-arrow">
            <IconArrow direction="down" />
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default FooterLang;
